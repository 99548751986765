.root
    margin-top: 2rem
    @media screen and (max-width: 1200px)
        margin-top: 0
    .content, .statisticContent
        // background: #FFFFFF
        // box-shadow: 0px 2px 10px 0px rgba(19, 17, 32, 0.15)
        // border-radius: 30px
        // padding: 1rem 3rem
    .statisticContent
        // padding: 3rem 5rem 4rem
        @media screen and (max-width: 1200px)
            z-index: 2
            // padding: 2rem 1rem 4rem
            // border-radius: 30px 30px 0 0
            box-shadow: none
        .desc
            font-size: 17px
            line-height: 36px
            hyphens: auto
        .button
            padding: 14px 24px
            margin-bottom: 3rem
            width: 100%
            font-size: 14px
            display: none
            text-transform: none
            @media screen and (max-width: 1200px)
                display: block
    .statistic
        display: flex
        @media screen and (max-width: 1200px)
            display: none
    .statisticMobile
        display: none
        margin: 1rem 0
        @media screen and (max-width: 1200px)
            display: flex
            justify-content: center
            gap: 16px
    .statistic, .statisticMobile
        .content
            display: flex
            width: calc(100% - 6rem)
            height: calc(100% - 2rem)
            flex-wrap: nowrap
            gap: 1.2rem
            justify-content: flex-start
            align-items: center
            cursor: pointer
            position: relative
            @media screen and (max-width: 1200px)
                justify-content: center
            &:hover
                background: #737791
                color: #FFF
                .icon
                    color: #FFF
                    & *
                        fill: #AE81E0
            .icon
                width: 30px
                height: 30px
                color: #AE81E0
                & *
                    fill: #AE81E0
            .name
                font-size: 17px
    .photos
        margin-top: 2rem
