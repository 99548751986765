.root
    position: relative
    padding: 2rem 3rem
    @media screen and (max-width: 1200px)
        padding: 2rem 1rem
    .list
        display: flex
        gap: 24px
        overflow: scroll
        -ms-overflow-style: none
        scrollbar-width: none
        &::-webkit-scrollbar
            display: none
        @media screen and (max-width: 1200px)
            left: 2rem
            right: 2rem
        .item.selected
            background: #737791
            color: #FFF
        .item
            display: flex
            align-items: center
            gap: 8px
            padding: 8px 24px
            background: #F5F5F5
            border-radius: 10px
            cursor: pointer
            &:hover
                background: #737791
                color: #FFF !important
                .bottom
                    color: #FFF
            .top
                font-size: 1.2rem
                font-weight: 600
                width: max-content
                text-transform: capitalize
            .bottom
                white-space: nowrap
